import {
    HttpClient,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpRequest,
    HttpParams,
} from '@angular/common/http';
import {
    inject,
    Injectable
} from '@angular/core';
import {
    AuthUtils
} from 'app/core/auth/auth.utils';
import {
    UserService
} from 'app/core/user/user.service';
import {
    catchError,
    Observable,
    of ,
    switchMap,
    throwError
} from 'rxjs';
import { environment } from './../../environments/environment';
import { DomainResponse } from 'app/core/models/domain-response';
import { delay, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DomainService {
    private _httpClient = inject(HttpClient);
    private readonly API_URL = environment.apiUrl;

    public getDomains(filter: string, sortDirection: 'asc' | 'desc' | '', sortField: string, 
            pageIndex: number, pageSize: number, activeFilters: Object[]): Observable<DomainResponse> {
        // seteamos los filters
        let allStringFilterAux = [];
        activeFilters.forEach((item, index) => {
            if (item['value'] != null && item['value'] != '') {
                let stringFilterAux = [item['id'],item['value']];
                allStringFilterAux.push(stringFilterAux.join('::'));
            }
        });

        let queryFilter = allStringFilterAux.join('--');

        // Seteamos los params
        let params = new HttpParams()
        .set('page', pageIndex.toString())
        .set('page_size', pageSize.toString())
        .set('sf', sortField?.toString())
        .set('s', sortDirection?.toString().toUpperCase())
        .set('f', queryFilter);

        return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/domains`, {
        params: params
        }).pipe(
        // map(res =>  res["data"])
        map(res =>  <DomainResponse>res)
        );    
        // return of(this.getFakeDomains(params)).pipe(delay(3000));
    }
    
    createDomain(domain: any): Observable < any > {
        return this._httpClient.post(`${this.API_URL}/domains`, domain);
    }

    editDomain(domain: any, id: string): Observable < any > {
        return this._httpClient.put(`${this.API_URL}/domains/${id}`, domain);
    }

    deleteDomain(id: string): Observable < any > {
        return this._httpClient.delete(`${this.API_URL}/domains/${id}`);
    }

    getDomain(id: string): Observable < any > {
        return this._httpClient.get(`${this.API_URL}/domains/${id}`);
    }
    

    getDomainCat(): Observable < any > {
        return this._httpClient.get(`${this.API_URL}/domains/categories`);
    }

    getWhoisDomain(id: string): Observable < any > {
        return this._httpClient.get(`${this.API_URL}/domains/${id}/whois`);
    }

    addWhoisDomain(domain: any, id: string): Observable < any > {
        return this._httpClient.put(`${this.API_URL}/domains/${id}/whois`, domain);
    }

    getHistoryHostDomain(id: string): Observable < any > {
        return this._httpClient.get(`${this.API_URL}/domains/${id}/history_host`);
    }

    getDomainsList(): Observable < any > {
        return this._httpClient.get(`${this.API_URL}/domains`);
    }
}
