import {
    Injectable
} from "@angular/core";
import {
    HttpClient
} from "@angular/common/http";
import {
    environment
} from "environments/environment";
import { Observable } from "rxjs";
import { Category } from "app/core/models/category.interface";
import { Os } from "app/core/models/os.interface";
import { Device } from "app/core/models/device.interface";
import { Browser } from "app/core/models/browser.interface";
import { Date } from "app/core/models/date.interface";
import { Hour } from "app/core/models/hour.interface";

@Injectable()
export class StadisticService {
    readonly BASE_PATH_STATS = '/statistics/p'

    constructor(private _httpClient: HttpClient) {}

    getBrowser(): Observable < Browser > {
        return this._httpClient.get<Browser>(`${environment.apiUrl + '/' + environment.apiVersion + this.BASE_PATH_STATS}/browser?adblock=all`);
    }

    getDevice(): Observable < Device > {
        return this._httpClient.get<Device>(`${environment.apiUrl + '/' + environment.apiVersion + this.BASE_PATH_STATS}/device?adblock=all`);
    }

    getOs(): Observable < Os > {
        return this._httpClient.get<Os>(`${environment.apiUrl + '/' + environment.apiVersion + this.BASE_PATH_STATS}/os?adblock=all`);
    }

    getCategory(): Observable < Category > {
        return this._httpClient.get<Category>(`${environment.apiUrl + '/' + environment.apiVersion + this.BASE_PATH_STATS}/category?adblock=all`);
    }

    getDate(): Observable < Date > {
        return this._httpClient.get<Date>(`${environment.apiUrl + '/' + environment.apiVersion + this.BASE_PATH_STATS}/date?adblock=all`);
    }

    getHour(): Observable < Hour > {
        return this._httpClient.get<Hour>(`${environment.apiUrl + '/' + environment.apiVersion + this.BASE_PATH_STATS}/hour?adblock=all`);
    }

}
