import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Project } from '../core/models/project';
import { ProjectParams } from '../core/models/project-params';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { ProjectResponse } from '../core/models/project-response';
import { environment } from "environments/environment";

@Injectable()
export class ProjectService {

  constructor(private _httpClient: HttpClient) { }

  public getProjects(filter: string, sortDirection: 'asc' | 'desc'| '', sortField: string, 
                      pageIndex: number, pageSize: number, activeFilters: Object[]): Observable<ProjectResponse> {
    // seteamos los filters
    let allStringFilterAux = [];
    activeFilters.forEach((item, index) => {
      if (item['value'] != null && item['value'] != '') {
        let stringFilterAux = [item['id'],item['value']];
        allStringFilterAux.push(stringFilterAux.join('::'));
      }
    });

    let queryFilter = allStringFilterAux.join('--');

    // Seteamos los params
    let params = new HttpParams()
    .set('page', pageIndex.toString())
    .set('page_size', pageSize.toString())
    .set('sf', sortField?.toString())
    .set('s', sortDirection?.toString().toUpperCase())
    .set('f', queryFilter);

    return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/projects`, {
      params: params
      }).pipe(
          // map(res =>  res["data"])
          map(res =>  <ProjectResponse>res)
      );    
    // return of(this.getFakeProjects(params)).pipe(delay(3000));
  }


  getProjectStatus(): Observable < any > {
      return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/projects/status`);
  }

  getProjectsList(): Observable < any > {
    return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/projects`);
}

getProject(id: string): Observable < any > {
    return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/projects/${id}`);
}

createProject(project: any): Observable < any > {
    return this._httpClient.post(`${environment.apiUrl}/${environment.apiVersion}/projects`, project);
}

editProject(project: any, id: string): Observable < any > {
    return this._httpClient.put(`${environment.apiUrl}/${environment.apiVersion}/projects/${id}`, project);
}

deleteProject(id: string): Observable < any > {
    return this._httpClient.delete(`${environment.apiUrl}/${environment.apiVersion}/projects/${id}`);
}

getProjecCountries(): Observable < any > {
    return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/projects/countries`);
}
  
  
}
