import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { User } from '../core/models/user';
import { UserParams } from '../core/models/user-params';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { UserResponse } from '../core/models/user-response';
import { environment } from "environments/environment";

@Injectable()
export class UserService {

  constructor(private _httpClient: HttpClient) { }

  public getUsers(filter: string, sortDirection: 'asc' | 'desc' | '', sortField: string, 
                      pageIndex: number, pageSize: number, activeFilters: Object[]): Observable<UserResponse> {
    // seteamos los filters
    let allStringFilterAux = [];
    activeFilters.forEach((item, index) => {
        if (item['value'] != null && item['value'] != '') {
            let stringFilterAux = [item['id'],item['value']];
            allStringFilterAux.push(stringFilterAux.join('::'));
        }
    });

    let queryFilter = allStringFilterAux.join('--');

    // Seteamos los params
    let params = new HttpParams()
    .set('page', pageIndex.toString())
    .set('page_size', pageSize.toString())
    .set('sf', sortField?.toString())
    .set('s', sortDirection?.toString().toUpperCase())
    .set('f', queryFilter);

    return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/users`, {
      params: params
      }).pipe(
          // map(res =>  res["data"])
          map(res =>  <UserResponse>res)
      );    
    // return of(this.getFakeUsers(params)).pipe(delay(3000));
  }


  getUserStatus(allStatus = null): Observable < any > {
    if(allStatus) {
      return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/users/status?all=true`);
    } else {
      return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/users/status`);
    }
  }

  getUserRoles(): Observable < any > {
    return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/users/roles`);
}

  // getUser(id?: string): Observable < any > {
  //   return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/users/${id}`);
  // }

  editUser(user: any, id: string): Observable < any > {
    return this._httpClient.put(`${environment.apiUrl}/${environment.apiVersion}/users/${id}`, user);
}

getUser(id?: string): Observable < any > {
  return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/users`);
}
  
  
}
