/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'home',
        title: 'Home',
        type : 'basic',
        icon : 'heroicons_outline:home',
        link : '/dashboard'
    },
    {
        id   : 'statistics',
        title: 'Statistics',
        type : 'basic',
        icon : 'mat_outline:area_chart',
        link : '/statistics'
    },
    {
        id   : 'links',
        title: 'Links',
        type : 'basic',
        icon : 'heroicons_outline:link',
        link : '/links',
    },
    {
        id   : 'campaigns',
        title: 'Campaigns',
        type : 'basic',
        icon : 'heroicons_outline:building-storefront',
        link : '/campaigns',
    },
    {
        id   : 'account',
        title: 'Account',
        type : 'group',
        icon : 'heroicons_outline:window',
        children: [
            {
                id   : 'payments',
                title: 'Payments',
                type : 'basic',
                icon : 'heroicons_outline:currency-euro',
                link : '/payments'
            },
            {
                id   : 'alerts',
                title: 'Alerts',
                type : 'basic',
                icon : 'heroicons_outline:bell-alert',
                link : '/alerts',
            },
            {
                id   : 'users',
                title: 'Users',
                type : 'basic',
                icon : 'heroicons_outline:users',
                link : '/users',
            }
        ],
    },
    {
        id   : 'help',
        title: 'Help',
        type : 'group',
        icon : 'heroicons_outline:question-mark-circle',
        children: [
            {
                id   : 'help.faqs',
                title: 'FAQs',
                type : 'basic',
                icon : 'heroicons_outline:information-circle',
                link : '/faq',
            },
            {
                id   : 'help.support',
                title: 'Support',
                type : 'basic',
                icon : 'heroicons_outline:chat-bubble-bottom-center-text',
                link : '/support',
            }
        ],
    }
];
export const compactNavigation: FuseNavigationItem[] = defaultNavigation;
export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation;
export const horizontalNavigation: FuseNavigationItem[] = defaultNavigation;

