import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Account } from '../core/models/account';
import { AccountParams } from '../core/models/account-params';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { AccountResponse } from '../core/models/account-response';
import { environment } from "environments/environment";

@Injectable()
export class AccountService {

  constructor(private _httpClient: HttpClient) { }

  public getAccounts(filter: string, sortDirection: 'asc' | 'desc' | '', sortField: string, 
                      pageIndex: number, pageSize: number, activeFilters: Object[]): Observable<AccountResponse> {
    // seteamos los filters
    let allStringFilterAux = [];
    activeFilters.forEach((item, index) => {
        if (item['value'] != null && item['value'] != '') {
            let stringFilterAux = [item['id'],item['value']];
            allStringFilterAux.push(stringFilterAux.join('::'));
        }
    });

    let queryFilter = allStringFilterAux.join('--');

    // Seteamos los params
    let params = new HttpParams()
    .set('page', pageIndex.toString())
    .set('page_size', pageSize.toString())
    .set('sf', sortField?.toString())
    .set('s', sortDirection?.toString().toUpperCase())
    .set('f', queryFilter);

    return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/accounts`, {
      params: params
      }).pipe(
          // map(res =>  res["data"])
          map(res =>  <AccountResponse>res)
      );    
    // return of(this.getFakeAccounts(params)).pipe(delay(3000));
  }


  getAccountStatus(): Observable < any > {
      return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/accounts/status`);
  }

  getAccount(id: string): Observable < any > {
    return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/accounts/${id}`);
  }

  editUser(account: any, id: string): Observable < any > {
    return this._httpClient.put(`${environment.apiUrl}/${environment.apiVersion}/accounts/${id}`, account);
  }

  getAccountTypes(): Observable < any > {
    return this._httpClient.get(`${environment.apiUrl}/${environment.apiVersion}/accounts/types`);
  }

  deleteAccount(id: string): Observable < any > {
    return this._httpClient.delete(`${environment.apiUrl}/${environment.apiVersion}/accounts/${id}`);
  }

  createAccount(account: any): Observable < any > {
    return this._httpClient.post(`${environment.apiUrl}/${environment.apiVersion}/accounts`, account);
  }
  
}
