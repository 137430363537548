import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Navigation } from 'app/core/navigation/navigation.types';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({providedIn: 'root'})
export class NavigationService
{
    private _httpClient = inject(HttpClient);
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
    private _translocoService = inject(TranslocoService);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation>
    {
        return this._httpClient.get<Navigation>('api/common/navigation').pipe(
            tap((navigation) =>
            {
                const translatedNavigation = this.translateNavigation(navigation);
                this._navigation.next(translatedNavigation);
            }),
        );
    }

    /**
     * Función recursiva para traducir la navegación y los subelementos
     */
    private translateNavigation(navigation: Navigation): Navigation {
        // Traducir los items principales
        // navigation.default = navigation.default.map((item) => this.translateMenuItem(item));
        navigation.horizontal = navigation.horizontal.map((item) => this.translateMenuItem(item));
        return navigation;
    }

    /**
     * Función para traducir cada item del menú
     */
    private translateMenuItem(item: any): any {
        const translatedItem = {
            ...item,
            title: this._translocoService.translate(item.title),
        };

        // Si el item tiene hijos, traducirlos también
        if (item.children) {
            translatedItem.children = item.children.map((child: any) =>
                this.translateMenuItem(child)
            );
        }

        return translatedItem;
    }
}
